import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer, MenuItem, Select, Box, useTheme, useMediaQuery, IconButton, AppBar, Toolbar } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearAuthToken } from '../redux/actions/actions';
import i18n from '../locales/i18n';
import { useLoc, l } from '../locales/lang';
import { setLanguage } from '../redux/actions/actions_ui';
import { Menu } from '@mui/icons-material';

export function LanguageSwitcher() {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const dispatch = useDispatch();

  const handleLanguageChange = (event) => {
      const selectedLanguage = event.target.value;
      setCurrentLanguage(selectedLanguage)
      i18n.changeLanguage(selectedLanguage);
      dispatch(setLanguage(selectedLanguage)); 
  };

  return (
      <Select
          value={currentLanguage}
          onChange={handleLanguageChange}
          style={{ width: '150px', marginBottom: '20px' }}
      >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="zh">中文</MenuItem>
      </Select>
  );
}
function SideMenu() {
  const loc = useLoc();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const drawerWidth = 240;
  const disableMenuPath = ['/login','/'];
  const disableMenu = (disableMenuPath.includes(location.pathname))?true:false;
  const menuItems = [
      // { label: loc(l.menuCreateCustomer), path: '/create-customer' },
      { label: loc(l.menuCustomerList), path: '/customer-list' },
      { label: loc(l.menuRegCodeList), path: '/reg-code-list' },
      // { label: loc(l.menuGenerateCode), path: '/generate-code' },
      // { label: loc(l.menuStaffManagement), path: '/staff-management' },
      { label: loc(l.menuLogout), path: '/login' },
  ];

  const menuItemsPress = (key,path)=>{
      if (key === loc(l.menuLogout)) {
          dispatch(clearAuthToken(),()=>{
            navigate('/login');
          });
      }else{
          navigate(path);
      }
      setDrawerOpen(false)
  }
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
      disableMenu?
      <></>:
      <div style={{boxShadow: ((isMobile)?'':'0px 0px 20px 0px black')}} onMouseEnter={() => setDrawerOpen(true)}>
      <AppBar position="sticky" style={{backgroundColor:'transparent',color:'black',boxShadow:'unset'}}>
        <IconButton style={{justifyContent: (isMobile)?'start':'center'}} edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
          <Menu style={{marginLeft:10}} />
        </IconButton>
      </AppBar>
        <Drawer
          variant='temporary'
          open={drawerOpen}
          onClose={toggleDrawer}
          anchor={(isMobile)?'top':'left'}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              height:'100%',
              boxSizing: 'border-box',
            },
          }}
        >
          <List style={{overflowY:'scroll',overflowX:'hidden',height:'85%',}}>
            {menuItems.map((item) => (
            <ListItem 
            button 
            key={item.label} 
            sx={{
              backgroundColor: location.pathname === item.path ? 'primary.main' : 'transparent', // Change the background color if selected
              color: location.pathname === item.path ? 'primary.contrastText' : 'text.primary', // Change the text color if selected
              '&:hover': {
                backgroundColor: location.pathname === item.path ? 'primary.main' : 'primary.dark',
                cursor: location.pathname === item.path ? 'unset' : 'cursor'
              }
            }}
            onClick={() => {
                menuItemsPress(item.label,item.path)
            }}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText primary={item.label} />
            </ListItem>
            ))}
          </List>
          <Box style={{position:'absolute', bottom:0}} alignItems="center" padding="1rem">
              <LanguageSwitcher />  
          </Box>
        </Drawer>
      </div>
  );
}

export default SideMenu;