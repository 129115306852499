import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import { lang } from './translation';
import { ChillConsole } from '../components/ChillHelper';

const lang_list = ['zh','en'];

export function useLoc(name){
    // const { t } = useTranslation();
    // const data = t(name);

    // return data;
    const { t } = useTranslation();
    return t;  // Return the t function itself
}
export function changeLang(lang){
    if(lang_list.includes(lang)){
        i18n.changeLanguage(lang);
    }
}

export const getLang = () => {
    const currentLanguage = i18n.language;
    return lang[currentLanguage] || lang['en']; 
};

export const l = lang.key