import React, { useState, useEffect, useRef } from 'react';
import { Box, List, ListSubheader, ListItem, ListItemText, CircularProgress, Typography, Grid, Button, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ChillBox, ChillConsole, ChillContainer, ChillLoading, ChillRowGrid, ChillSelect, ChillTextField, chillApiCheck, generateNumberArray, getRefValue } from '../components/ChillHelper';
import { useLoc, l } from '../locales/lang';
import { post } from '../redux/apis';
import { useDispatch } from 'react-redux';

function AddRegCodePage() {
  const loc = useLoc();
  const hasMounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const { customerCode } = useParams();
  const [customers, setCustomers] = useState([]);
  const customerRef = useRef(null);
  const typeRef = useRef(null);
  const qtyRef = useRef(null);
  const qty_max = 50;
  const qty_list = [5,10,20,40,60,80]
  const type_list = ['MONTH','YEAR'];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasMounted.current) return;

    hasMounted.current = true;

    fetchCustomerList();
  }, []);
  
  const fetchCustomerList = async () => {
    if(loading){ return; }
    
    setLoading(true);

    const response = await post('customer_info_list', {});

    setLoading(false);

    if (response?.success) {
      const data = response.data.CUSTOMER
      if(data.length>0){
        setCustomers(data); 
      }
    } else {
      chillApiCheck(response,navigate,dispatch)
    }
  };

  const handleSubmit = async () => {
    if(loading){ return; }

    setLoading(true);

    const type = getRefValue(typeRef)
    const qty = getRefValue(qtyRef)
    const customer_code = getRefValue(customerRef)
    if(type=='' || qty==''){
      alert(loc(l.addRegCodeAlertTypeQuantityRequired))
      setLoading(false);
      return;
    }else{
      const data = {
        CUSTOMER_CODE:customer_code,
        QTY:qty,
      }
      const api = (type=='MONTH')?'gen_code_month':(type=='YEAR')?'gen_code_year':''
      const response = await post(api, data);

      setLoading(false);
      
      if (response?.success) {
        alert(response.message);
      } else {
        chillApiCheck(response,navigate,dispatch)
      }

    }

    
  };

  return (
    <ChillContainer onSubmit={handleSubmit} >
      <Box spacing={3}>
        <Typography variant="h4" mb={3}>{loc(l.addRegCodeTitle)}</Typography>
        <ChillSelect fullw ref={qtyRef} label={loc(l.addRegCodeQuantity)} data={qty_list} />
        <ChillSelect fullw ref={typeRef} label={loc(l.addRegCodeType)} data={type_list} />
        <ChillSelect fullw ref={customerRef} label={loc(l.addRegCodeCustomer)} value={customerCode} data={customers} main_key={'ID'} value_key={'CUSTOMER_CODE'} display_key={['CUSTOMER_CODE','NAME']} />
        <Button variant="contained" color="primary" type="submit">{loc(l.createCustomerSubmitBtn)}</Button>
      </Box>
      <ChillLoading loading={loading} />
    </ChillContainer>
  );
}

export default AddRegCodePage;
