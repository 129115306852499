import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { ChillConsole } from './ChillHelper';

function ProtectedRoute({ children }) {
  const location = useLocation();
  const authToken = useSelector(state => state.auth.authToken);
  const isAuthenticated = authToken;

  ChillConsole('authToken',authToken)
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default ProtectedRoute;