import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLoc, l } from '../locales/lang';
import { ChillBox, ChillContainer, ChillLoading, ChillTextField, chillApiCheck } from '../components/ChillHelper';
import { post } from '../redux/apis';
import { useDispatch } from 'react-redux';

function CreateCustomer() {
  const loc = useLoc();
  const [customerCode, setCustomerCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if(loading){ return; }

    // Make a POST request to your API to create a new customer
    const data = {
      CUSTOMER_CODE: customerCode.trim().toUpperCase(),
      NAME: name,
      EMAIL: email,
      PHONE: phone,
    }
    setLoading(true);

    const response = await post('customer_info_add', data);

    setLoading(false);
    if (response?.success) {
      alert('Customer created successfully!');
      navigate('/customer-list');  
    } else {
      chillApiCheck(response,navigate,dispatch)
    }
  };

  return (
    <ChillContainer onSubmit={handleSubmit}>
      <Box spacing={3}>
        <Typography variant="h4" mb={3}>{loc(l.createCustomerTitle)}</Typography>
        <ChillTextField
          label={loc(l.createCustomerCode)}
          variant="outlined"
          name="CUSTOMER_CODE"
          value={customerCode}
          onChange={(e)=>setCustomerCode(e.target.value)}
          fullw
          required
        />
        <ChillTextField
          label={loc(l.createCustomerName)}
          variant="outlined"
          name="NAME"
          value={name}
          onChange={(e)=>setName(e.target.value)}
          fullw
          required
        />
        <ChillTextField
          label={loc(l.createCustomerEmail)}
          variant="outlined"
          name="EMAIL"
          value={email}
          type='email'
          onChange={(e)=>setEmail(e.target.value)}
          fullw
          required
        />
        <ChillTextField
          label={loc(l.createCustomerPhone)}
          variant="outlined"
          name="PHONE"
          value={phone}
          type='number'
          onChange={(e)=>setPhone(e.target.value)}
          fullw
          required
        />
        <Button variant="contained" color="primary" type="submit">{loc(l.createCustomerSubmitBtn)}</Button>
      </Box>
      <ChillLoading loading={loading} />
    </ChillContainer>
  );
}

export default CreateCustomer;
