import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState, forwardRef, createContext, useContext, useEffect  } from 'react';
import { l, useLoc } from '../locales/lang';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { ChillLoading } from './ChillHelper';

export const RegCodeDialog = (props)=>{
    const loc = useLoc();
    const navigate = useNavigate();
    const loading = props?.loading || false
    const open = props?.dialogOpen || false
    const { ID, CODE, CUSTOMER_NAME, CUSTOMER_ID, TYPE, EXPIRY_TIME, REG_TIMES } = props?.data || {}
  
    const handleClose = () => {
      if(loading){ return }
  
      if(props.onClose){
        props.onClose()
      }
    };
    const handleSave = () => {
      if(loading){ return }
  
      if(props.onSave){
        const data = {
          ID:ID,
          CODE: CODE,
          EXPIRY_TIME: EXPIRY_TIME,
        }
        props.onSave(data)
      }
    };
    const handleCustomerDetails = () => {
      if(loading){ return }
  
      navigate(`/customer/${CUSTOMER_ID}`)
    };
    
    return(
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{loc(l.dialogRegCodeTitle)}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{loc(l.dialogRegCode)} {CODE}</Typography>
          <Typography variant="body1">{loc(l.dialogRegCodeType)} {TYPE}</Typography>
          <Typography variant="body1">{loc(l.dialogRegCodeExpiryTime)} {EXPIRY_TIME}</Typography>
          <Typography variant="body1">{loc(l.dialogRegCodeRegTimes)} {REG_TIMES}</Typography>
          <Typography variant="body1">{loc(l.dialogRegCodeCustomerName)} <Button onClick={handleCustomerDetails}>{CUSTOMER_NAME}</Button></Typography>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {loc(l.dialogRegCodeCancel)}
          </Button>
          <Button onClick={handleSave} color="primary" disabled={EXPIRY_TIME==null} >
            {loc(l.dialogRegCodeExtend)}
          </Button>
        </DialogActions>
        <ChillLoading loading={loading} />
      </Dialog>
    );
}