import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './reducer';
import uiReducer from './reducer_ui';


const persistConfig = {
  key: 'root',
  storage,
  // blacklist: [] 
  whitelist: ['auth','ui'] 
};

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;