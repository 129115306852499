import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, Box, useTheme, useMediaQuery } from '@mui/material';
import './locales/i18n';
import './App.css';  
import SideMenu from './components/SideMenu'; 
import CreateCustomer from './views/CreateCustomer';
import CustomerListPage from './views/CustomerListPage';
import CustomerDetails from './views/CustomerDetails';
import AddRegCodePage from './views/AddRegCodePage';
import StaffManagementPage from './views/StaffManagementPage';
import LoginPage from './views/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import { store, persistor } from './redux/store';
import i18n from './locales/i18n';
import { useSelector } from 'react-redux';
import { WindowHeightProvider } from './components/ChillHelper';
import RegCodeListPage from './views/RegCodeListPage';

// Create a Material-UI theme (customize as needed)
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Change this to your primary color
    },
    secondary: {
      main: '#ff4081', // Change this to your secondary color
    },
  },
});


function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentLanguage = useSelector(state => state.ui.currentLanguage);
  if (i18n.language !== currentLanguage) {
      i18n.changeLanguage(currentLanguage);
  }

  return (
    <ThemeProvider theme={theme}>
      <WindowHeightProvider>
        <Router>
          <CssBaseline />
          <Box display="flex" style={{height: '100%',flexDirection: isMobile?'column':'row'}}>
            <SideMenu />
            <Box component="main" style={{padding:(isMobile)?0:24}} flexGrow={1} p={3}>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/create-customer" element={ <ProtectedRoute><CreateCustomer /></ProtectedRoute> } />
                <Route path="/customer-list" element={ <ProtectedRoute><CustomerListPage /></ProtectedRoute> } />
                <Route path="/customer/:customerId" element={ <ProtectedRoute><CustomerDetails /></ProtectedRoute> } />
                <Route path="/reg-code-list" element={ <ProtectedRoute><RegCodeListPage /></ProtectedRoute> } />
                <Route path="/add-reg-code/:customerCode" element={ <ProtectedRoute><AddRegCodePage /></ProtectedRoute> } />
                {/* <Route path="/staff-management" element={ <ProtectedRoute><StaffManagementPage /></ProtectedRoute> } /> */}
                <Route path="*" element={<LoginPage />} />
              </Routes>
            </Box>
          </Box>
        </Router>
      </WindowHeightProvider>
    </ThemeProvider>
  );
}

export default App;
