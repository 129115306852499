import React, { useEffect, useState } from 'react';
import { Button, TextField, Container, Typography, Grid, IconButton, InputAdornment, Fade, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken } from '../redux/actions/actions';
import SHA256 from 'crypto-js/sha256';
import { ChillContainer, ChillLoading, ChillPasswordField, ChillTextField, chillApiCheck } from '../components/ChillHelper';
import { useLoc, l } from '../locales/lang';
import { post } from '../redux/apis';
import logo from '../assets/cms.png'
import { LanguageSwitcher } from '../components/SideMenu';

function LoginPage() {
    const loc = useLoc();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const from = '/customer-list' // location.state || '/customer-list'
    const authToken = useSelector(state => state.auth.authToken);
    const isAuthenticated = authToken;
  
    useEffect(()=>{
        if (isAuthenticated) {
            navigate(from);
        }
    },[])

    const handleLogin = async () => {
        
        if(loading){ return; }

        if (username && password) {
            const hashedPassword = SHA256(password).toString();
            
            const data = {
                USERNAME: username,
                PWD: hashedPassword,
            }

            setLoading(true);

            const response = await post('login', data);
    
            setLoading(false);
            if (response?.success) {
                const token = response.token;
                dispatch(setAuthToken(token));  // Store the token in Redux
                navigate(from);
            } else {
                chillApiCheck(response,navigate,dispatch)
            }

        } else {
            // Show an error message or handle the failed login attempt as needed.
            alert('Please enter a valid username and password.');
        }

    };


    return (
    <ChillContainer onSubmit={handleLogin}>
        <Fade in={true} timeout={2000}>
            <Box className="fade-container">
                <img style={{width:100}} src={logo} alt="Login" />
            </Box>
        </Fade>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ChillTextField 
                    label={loc(l.loginUsername)}
                    value={username}
                    fullw
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <ChillPasswordField 
                    name={loc(l.loginPassword)}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                // onClick={handleLogin}
                >
                    {loc(l.loginBtn)}
                </Button>
            </Grid>
        </Grid>
        <Grid style={{position:'absolute', bottom:0}} alignItems="center" padding="1rem">
            <LanguageSwitcher />  
        </Grid>
      <ChillLoading loading={loading} />
    </ChillContainer>
    );
}

export default LoginPage;
