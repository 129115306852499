import { SET_AUTH_TOKEN, CLEAR_AUTH_TOKEN } from '../actions/actions';

const initialState = {
  authToken: null 
};

const setAuthToken = (state, action) => ({
  ...state,
  authToken: action.data
});

const clearAuthToken = (state) => ({
  ...state,
  authToken: null
});

const actionHandlers = {
  [SET_AUTH_TOKEN]: setAuthToken,
  [CLEAR_AUTH_TOKEN]: clearAuthToken
};

const authReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default authReducer;
