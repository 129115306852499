export const lang = {
    key:{
        loginTitle:"loginTitle",
        loginUsername:"loginUsername",
        loginPassword:"loginPassword",
        loginBtn:"loginBtn",
        menuCreateCustomer:"menuCreateCustomer",
        menuCustomerList:"menuCustomerList",
        menuGenerateCode:"menuGenerateCode",
        menuRegCodeList:"menuRegCodeList",
        menuStaffManagement:"menuStaffManagement",
        menuLogout:"menuLogout",
        createCustomerTitle:"createCustomerTitle",
        createCustomerCode:"createCustomerCode",
        createCustomerName:"createCustomerName",
        createCustomerEmail:"createCustomerEmail",
        createCustomerPhone:"createCustomerPhone",
        createCustomerSubmitBtn:"createCustomerSubmitBtn",
        customerListTitle:"customerListTitle",
        customerListCustomerCode:"customerListCustomerCode",
        customerListName:"customerListName",
        customerListEmail:"customerListEmail",
        customerListPhone:"customerListPhone",
        customerListDetailsBtn:"customerListDetailsBtn",
        customerDetailsTitle:"customerDetailsTitle",
        customerDetailsName:"customerDetailsName",
        customerDetailsEmail:"customerDetailsEmail",
        customerDetailsPhone:"customerDetailsPhone",
        customerDetailsRegCodeCreate:"customerDetailsRegCodeCreate",
        regCodeListTitle:"regCodeListTitle",
        regCodeListCode:"regCodeListCode",
        regCodeListType:"regCodeListType",
        regCodeListExpiryTime:"regCodeListExpiryTime",
        regCodeListDetailsBtn:"regCodeListDetailsBtn",
        regCodeListFilterText:"regCodeListFilterText",
        dialogRegCodeTitle:"dialogRegCodeTitle",
        dialogRegCode:"dialogRegCode",
        dialogRegCodeType:"dialogRegCodeType",
        dialogRegCodeExpiryTime:"dialogRegCodeExpiryTime",
        dialogRegCodeRegTimes:"dialogRegCodeRegTimes",
        dialogRegCodeCustomerName:"dialogRegCodeCustomerName",
        dialogRegCodeCancel:"dialogRegCodeCancel",
        dialogRegCodeExtend:"dialogRegCodeExtend",
        addRegCodeTitle:"addRegCodeTitle",
        addRegCodeCustomer:"addRegCodeCustomer",
        addRegCodeQuantity:"addRegCodeQuantity",
        addRegCodeType:"addRegCodeType",
        addRegCodeAlertTypeQuantityRequired:"addRegCodeAlertTypeQuantityRequired",

        logout:"logout"
    },
    en: {
        translation:{
            loginTitle:"Login",
            loginUsername:"Username",
            loginPassword:"Password",
            loginBtn:"LOGIN",
            menuCreateCustomer:"Create Customer",
            menuCustomerList:"Customer List",
            menuGenerateCode:"Generate Reg Code",
            menuRegCodeList:"Reg Code List",
            menuStaffManagement:"Staff Management",
            menuLogout:"Logout",
            createCustomerTitle:"Create Customer",
            createCustomerCode:"Customer Code",
            createCustomerName:"Name",
            createCustomerEmail:"Email",
            createCustomerPhone:"Phone",
            createCustomerSubmitBtn:"Create",
            customerListTitle:"Customer List",
            customerListCustomerCode:"Customer Code",
            customerListName:"Name",
            customerListEmail:"Email",
            customerListPhone:"Phone",
            customerListDetailsBtn:"Details",
            customerDetailsTitle:"Customer Details",
            customerDetailsName:"Name:",
            customerDetailsEmail:"Email:",
            customerDetailsPhone:"Phone:",
            customerDetailsRegCodeCreate:"Create Reg Code",
            regCodeListTitle:"Reg Code List",
            regCodeListCode:"Code",
            regCodeListType:"Type",
            regCodeListExpiryTime:"Expiry Time",
            regCodeListDetailsBtn:"Details",
            regCodeListFilterText:"Search",
            dialogRegCodeTitle:"Reg Code info / Extend",
            dialogRegCode:"Reg Code:",
            dialogRegCodeType:"Type:",
            dialogRegCodeExpiryTime:"Expiry Time:",
            dialogRegCodeRegTimes:"Reg Times:",
            dialogRegCodeCustomerName:"Customer Name:",
            dialogRegCodeCancel:"Cancel",
            dialogRegCodeExtend:"Extend",
            addRegCodeTitle:"Generate Reg Code",
            addRegCodeCustomer:"Customer:",
            addRegCodeQuantity:"Quantity:",
            addRegCodeType:"Type:",
            addRegCodeAlertTypeQuantityRequired:"Please select Type & Quantity",

            logout:"Logout"
        }
    },
    zh: {
        translation:{
            loginTitle:"登入",
            loginUsername:"用戶名稱",
            loginPassword:"密碼",
            loginBtn:"登入",
            menuCreateCustomer:"創建客戶",
            menuCustomerList:"客戶列表",
            menuGenerateCode:"生成註冊碼",
            menuRegCodeList:"註冊碼列表",
            menuStaffManagement:"員工管理",
            menuLogout:"登出",
            createCustomerTitle:"創建客戶",
            createCustomerCode:"客戶編號",
            createCustomerName:"名稱",
            createCustomerEmail:"電郵",
            createCustomerPhone:"電話",
            createCustomerSubmitBtn:"創建",
            customerListTitle:"客戶列表",
            customerListCustomerCode:"客戶編號",
            customerListName:"名稱",
            customerListEmail:"電郵",
            customerListPhone:"電話",
            customerListDetailsBtn:"詳情",
            customerDetailsTitle:"用戶詳情",
            customerDetailsName:"名稱:",
            customerDetailsEmail:"電郵:",
            customerDetailsPhone:"電話:",
            customerDetailsRegCodeCreate:"新增註冊碼",
            regCodeListTitle:"註冊碼列表",
            regCodeListCode:"註冊碼",
            regCodeListType:"類型",
            regCodeListExpiryTime:"有效期",
            regCodeListDetailsBtn:"詳情",
            regCodeListFilterText:"搜尋",
            dialogRegCodeTitle:"註冊碼詳情 / 延長",
            dialogRegCode:"註冊碼:",
            dialogRegCodeType:"類型:",
            dialogRegCodeExpiryTime:"有效期:",
            dialogRegCodeRegTimes:"註冊次數:",
            dialogRegCodeCustomerName:"客戶名:",
            dialogRegCodeCancel:"取消",
            dialogRegCodeExtend:"延長",
            addRegCodeTitle:"生成註冊碼",
            addRegCodeCustomer:"客戶:",
            addRegCodeQuantity:"數量:",
            addRegCodeType:"類型:",
            addRegCodeAlertTypeQuantityRequired:"請選擇 類型 及 數量",

            logout:"登出"
        }
    }
};
