import React, { useState, useEffect, useRef } from 'react';
import { Box, List, ListSubheader, ListItem, ListItemText, CircularProgress, Typography, Grid, Button, Container, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChillBox, ChillConsole, ChillContainer, ChillLoading, ChillRowGrid, ChillSearchTextField, ChillTable, chillApiCheck } from '../components/ChillHelper';
import { useLoc, l } from '../locales/lang';
import { post } from '../redux/apis';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import moment from 'moment';
import { useDispatch } from 'react-redux';

function CustomerListPage() {
  const loc = useLoc();
  const hasMounted = useRef(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasMounted.current) return;

    hasMounted.current = true;

    const fetchCustomers = async () => {
      if(loading){ return; }
      
      setLoading(true);

      const response = await post('customer_info_list', {});

      setLoading(false);

      if (response?.success) {
        const data = response.data.CUSTOMER
        if(data.length>0){
          setCustomers(data); 
        }
      } else {
        chillApiCheck(response,navigate,dispatch)
      }
    };
    fetchCustomers();
  }, []);

  const tableHeader = [
    {name:loc(l.customerListCustomerCode),key:'CUSTOMER_CODE',},
    {name:loc(l.customerListName),key:'NAME',},
    {name:loc(l.customerListEmail),key:'EMAIL',},
    {name:loc(l.customerListPhone),key:'PHONE',},
  ]

  const handleCustomerDetails = (id) =>{
    navigate(`/customer/${id}`)
  }
  
  const handleSearch = (value)=>{
    setFilter(value);
  }

  const AddCustomerButton = ()=>{
    return(
      <IconButton aria-label="add" onClick={() => { navigate(`/create-customer`) }}>
        <ControlPointIcon />
      </IconButton>
    )
  }
  return (
    <ChillContainer>
      <Typography variant="h4" mb={3}>{loc(l.customerListTitle)} <AddCustomerButton /> </Typography>
      <ChillSearchTextField
        label={loc(l.regCodeListFilterText)}
        value={filter}
        required
        onChange={(e)=>handleSearch(e.target.value)}
        onCancel={()=>handleSearch('')}
      />
      <ChillLoading loading={loading} />
      <ChillTable filter={filter} header={tableHeader} tableTop={180} content={customers} handleOther={handleCustomerDetails} otherButtonText={loc(l.customerListDetailsBtn)} excelName={'Reg Code List'+moment().format('YYYY-MM-DD')} />
    </ChillContainer>
  );
}

export default CustomerListPage;
