import { SET_LANGUAGE } from '../actions/actions_ui';

const initialState = {
  currentLanguage: 'en'
};

const setLanguage = (state, action) => ({
  ...state,
  currentLanguage: action.data
});

const actionHandlers = {
  [SET_LANGUAGE]: setLanguage,
};

const uiReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default uiReducer;
