import React, { useState, useEffect } from 'react';
import { Box, List, ListSubheader, ListItem, ListItemText, CircularProgress, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChillBox, ChillConsole, ChillContainer, ChillLoading, ChillRowGrid } from '../components/ChillHelper';
import { useLoc, l } from '../locales/lang';
import { useDispatch } from 'react-redux';

function StaffManagementPage() {
  const loc = useLoc();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch('/api/customers');  // Adjust the endpoint as needed
        if (!response.ok) {
          throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        setCustomers(data);
      } catch (err) {
        ChillConsole(err.message);
      } finally {
        setLoading(false);
      }
    };
    setCustomers([
        {
            id: 1,
            name:'AAA',
            email:'aaa@aaa.com'
        }
    ])
    setLoading(false);
    // fetchCustomers();
  }, []);

  return (
    <ChillContainer>
      <Typography variant="h4" mb={3}>{loc(l.customerListTitle)}</Typography>
      <ChillLoading loading={loading} />
      <List>
        <ListSubheader>
            <ChillRowGrid>
                <Grid item xs={4}>{loc(l.customerListName)}</Grid>
                <Grid item xs={4}>{loc(l.customerListEmail)}</Grid>
                <Grid item xs={4}></Grid>
            </ChillRowGrid>
        </ListSubheader>
        {customers.map((customer) => (
          <ListItem key={customer.id} >
            <ChillRowGrid>
                <Grid item xs={4}>
                    <ListItemText primary={customer.name} />
                </Grid>
                <Grid item xs={4}>
                    <ListItemText primary={customer.email} />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained" 
                        color="primary" 
                        onClick={() => navigate(`/customer/${customer.id}`)}
                    >
                      {loc(l.customerListDetailsBtn)}
                    </Button>
                </Grid>
            </ChillRowGrid>
          </ListItem>
        ))}
      </List>
    </ChillContainer>
  );
}

export default StaffManagementPage;
