import React, { useState, useEffect, useRef } from 'react';
import { Box, List, ListSubheader, ListItem, ListItemText, CircularProgress, Typography, Grid, Button, Container, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChillBox, ChillConsole, ChillContainer, ChillDialog, ChillLoading, ChillRowGrid, ChillSearchTextField, ChillTable, ChillTextField, chillApiCheck, getRefValue } from '../components/ChillHelper';
import { useLoc, l } from '../locales/lang';
import { post } from '../redux/apis';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import moment from 'moment/moment';
import { RegCodeDialog } from '../components/appHelper';
import { useDispatch } from 'react-redux';


function RegCodeListPage() {
  const loc = useLoc();
  const hasMounted = useRef(false);
  const [codeList, setCodeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasMounted.current) return;

    hasMounted.current = true;

    fetchRegCodeList();
  }, []);

  
  const fetchRegCodeList = async () => {
    if(loading){ return; }
    
    setLoading(true);

    const response = await post('reg_code_list', {});

    setLoading(false);

    if (response?.success) {
      const data = response.data.REG_CODE
      if(data.length>0){
        const data_display = data.map(item => {
          return {...item, 
            CODE_DISPLAY: item.CODE+((item.CUSTOMER_CODE)?' ('+item.CUSTOMER_CODE+')':''),
            EXPIRY_TIME_DISPLAY: (item.EXPIRY_TIME)?item.EXPIRY_TIME.replace('T',' ').replace('.000Z',''):null
          };
        });
        setCodeList(data_display); 
      }
    } else {
      chillApiCheck(response,navigate,dispatch)
    }
  };

  const tableHeader = [
    {name:'ID',key:'ID',},
    {name:loc(l.regCodeListCode),key:'CODE_DISPLAY',},
    {name:loc(l.regCodeListType),key:'TYPE',},
    {name:loc(l.regCodeListExpiryTime),key:'EXPIRY_TIME_DISPLAY',},
  ]
  const handleSearch = (value)=>{
    setFilter(value);
  }
  const handleDetails = (id)=>{
    const item = codeList.filter(f=>f.ID==id)
    setDialogData(item[0])
    setDialogOpen(true);
  }
  const handleDialogClose = ()=>{
    setDialogData({})
    setDialogOpen(false);
  }
  const handleDialogSave = async(item)=>{
    if(!item.CODE || item.EXPIRY_TIME==null){ 
      alert(`Code not register yet`);
      setDialogOpen(!dialogOpen);
      return; 
    }
    
    setLoading(true);

    // Make a POST request to your API to create a new customer
    const data = {
      CODE: item.CODE,
    }

    const response = await post('reg_code_extend_expiry_time', data);

    setLoading(false);
    if (response?.success) {
      alert('Reg Code extend successfully!');
      fetchRegCodeList();
    } else {
      chillApiCheck(response,navigate,dispatch)
    }


    setDialogOpen(!dialogOpen);
  }

  const AddDeviceCodeButton = ()=>{
    return(
      <IconButton aria-label="add" onClick={() => { navigate(`/add-reg-code`) }}>
        <ControlPointIcon />
      </IconButton>
    )
  }
  return (
    <ChillContainer>
      <Typography variant="h4" mb={3}>{loc(l.regCodeListTitle)} <AddDeviceCodeButton /> </Typography>
      <ChillSearchTextField
        label={loc(l.regCodeListFilterText)}
        value={filter}
        required
        onChange={(e)=>handleSearch(e.target.value)}
        onCancel={()=>handleSearch('')}
      />
      <ChillTable filter={filter} header={tableHeader} tableTop={180} content={codeList} handleOther={handleDetails} otherButtonText={loc(l.regCodeListDetailsBtn)} excelName={'Reg Code List'+moment().format('YYYY-MM-DD')} />
      <RegCodeDialog loading={loading} dialogOpen={dialogOpen} data={dialogData} onClose={handleDialogClose} onSave={handleDialogSave}/>
      <ChillLoading loading={loading} />
    </ChillContainer>
  );
}

export default RegCodeListPage;
