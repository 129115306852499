import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, TextField, Typography, Tabs, Tab, useTheme, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ChillBox, ChillConsole, ChillContainer, ChillLoading, ChillRowGrid, ChillTable, ChillTextField, chillApiCheck, getRefValue } from '../components/ChillHelper';
import { useLoc, l } from '../locales/lang';
import moment from 'moment';
import { post } from '../redux/apis';
import { RegCodeDialog } from '../components/appHelper';
import { useDispatch } from 'react-redux';


function CustomerDetails() {
  const loc = useLoc();
  const hasMounted = useRef(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tableHeader = [
    {name:'Reference',key:'ref'},
    {name:'Amount',align:'right',key:'amount'},
    {name:'Date',key:'datetime'},
  ]
  const regCodeHeader = [
    {name:loc(l.regCodeListCode),key:'CODE'},
    {name:loc(l.regCodeListType),key:'TYPE'},
    {name:loc(l.regCodeListExpiryTime),key:'EXPIRY_TIME'},
  ]
  const [selectedTab, setSelectedTab] = useState(0);
  const { customerId } = useParams();  // Extract the customer ID from the URL
  const [customerDetails, setCustomerDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [codeList, setCodeList] = useState([]);
  const [paymentRefs, setPaymentRefs] = useState([{ref:'aaaa',amount:500,datetime:'2023-08-22'}]); 
  const [editIndex, setEditIndex] = useState(null);
  const [currentRef, setCurrentRef] = useState('');
  const [amount, setAmount] = useState(0);
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCustomerDetails = async () => {
    
    setLoading(true);
    const data = {
      ID: customerId,
    }
    const response = await post('customer_info', data);

    setLoading(false);

    if (response?.success) {
      const data = response.data.CUSTOMER
      setCustomerDetails(data); 
    } else {
      chillApiCheck(response,navigate,dispatch)
    }

  };
  const fetchCustomerRegCodeList = async () => {
  
    const data = {
      ID: customerId,
    }
    const response = await post('customer_reg_code_list', data);
    if (response?.success) {
      const data = response.data.REG_CODE
      setCodeList(data)
    }else{
      chillApiCheck(response,navigate,dispatch)
    }

  };

  useEffect(() => {
    if (hasMounted.current) return;

    hasMounted.current = true;


    fetchCustomerDetails();
    fetchCustomerRegCodeList();
  }, [customerId]);

  const resetAddInput = () =>{
    setEditIndex(null);
    setCurrentRef('');
    setAmount(0);
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAddUpdateRef = () => {
    if(editIndex!==null){
      handleUpdateRef()
    }else{
      handleAddRef()
    }
  }
  const handleAddRef = () => {
    setPaymentRefs([...paymentRefs, { ref: currentRef, amount:amount, datetime: moment().utc().format('YYYY-MM-DD') }]);
    resetAddInput()
  };

  const handleEditRef = (index) => {
    setCurrentRef(paymentRefs[index].ref);
    setAmount(paymentRefs[index].amount);
    setEditIndex(index);
  };

  const handleUpdateRef = () => {
    const updatedRefs = [...paymentRefs];
    updatedRefs[editIndex].ref = currentRef;
    updatedRefs[editIndex].amount = amount;
    setPaymentRefs(updatedRefs);
    resetAddInput()
  };

  const handleDeleteRef = (index) => {
      const updatedRefs = [...paymentRefs];
      updatedRefs.splice(index, 1);
      setPaymentRefs(updatedRefs);
  };

  const handleCustomerEditClick = () => {
    setIsEditingCustomer(true);
  };

  const handleCustomerSaveClick = async(data) => {
    let isChange = false;
    const d = customerDetails
    if(
      d.NAME !==data.NAME ||
      d.EMAIL!==data.EMAIL ||
      d.PHONE!==data.PHONE 
    ){
      isChange = true
    }

    if(isChange){
      d.NAME = data.NAME
      d.EMAIL = data.EMAIL
      d.PHONE = data.PHONE
      
      setLoading(true);
      
      const response = await post('customer_info_add_update', d);
  
      setLoading(false);
  
      if (response?.success) {
        setIsEditingCustomer(false);
      } else {
        chillApiCheck(response,navigate,dispatch)
      }
    }else{
      setIsEditingCustomer(false);
    }
  };
  
  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    ChillConsole('name',name)
    ChillConsole('value',value)
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };


  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const EditInfo = ({customerDetails,onSubmit}) =>{
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const { NAME, EMAIL, PHONE } = customerDetails

  const submit = ()=>{
    if(onSubmit){
      const name = getRefValue(nameRef)
      const email = getRefValue(emailRef) 
      const phone = getRefValue(phoneRef) 
      const data = {
        NAME: name,
        EMAIL: email,
        PHONE: phone,
      }
      onSubmit(data)
    }
  } 

    return(
        <ChillBox just={"space-evenly"} component="form" onSubmit={submit}>
          <ChillLoading loading={loading} />
            <ChillTextField
              ref={nameRef}
              label={loc(l.customerDetailsName)}
              value={NAME}
              required
            />
            <ChillTextField
              ref={emailRef}
              label={loc(l.customerDetailsEmail)}
              value={EMAIL}
              required
              type='email'
            />
            <ChillTextField
              ref={phoneRef}
              label={loc(l.customerDetailsPhone)}
              value={PHONE}
              required
              type='number'
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
            >
              Save
            </Button>
        </ChillBox>
    );
  }
  const DisplayInfo = ({customerDetails,onEdit}) =>{
    const { NAME, EMAIL, PHONE } = customerDetails
    const edit = ()=>{
      if(onEdit){ onEdit() }
    } 
    return(
      <ChillRowGrid just={"space-evenly"}>
        <div>
          <Typography variant="body1">{loc(l.customerDetailsName)} {NAME}</Typography>
          <Typography variant="body1">{loc(l.customerDetailsEmail)} {EMAIL}</Typography>
          <Typography variant="body1">{loc(l.customerDetailsPhone)} {PHONE}</Typography>
        </div>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<EditIcon />}
          onClick={edit}
        >
          Edit
        </Button>
      </ChillRowGrid>
    );
  }
  const handleDetails = (id)=>{
    const item = codeList.filter(f=>f.ID==id)
    setDialogData(item[0])
    setDialogOpen(true);
  }
  const handleDialogClose = ()=>{
    setDialogData({})
    setDialogOpen(false);
  }
  const handleRegCodeCreate = ()=>{
    const code = customerDetails?.CUSTOMER_CODE || ""
    navigate(`/add-reg-code/${code}`)
  }
  const handleDialogSave = async(item)=>{
    if(!item.CODE || item.EXPIRY_TIME==null){ 
      alert(`Code not register yet`);
      setDialogOpen(!dialogOpen);
      return; 
    }
    
    setLoading(true);

    // Make a POST request to your API to create a new customer
    const data = {
      CODE: item.CODE,
    }

    const response = await post('reg_code_extend_expiry_time', data);

    setLoading(false);
    if (response?.success) {
      alert('Reg Code extend successfully!');
      fetchCustomerRegCodeList();
    } else {
      chillApiCheck(response,navigate,dispatch)
    }


    setDialogOpen(!dialogOpen);
  }

  return (
    <ChillContainer>
      <Typography variant="h4">{loc(l.customerDetailsTitle)} ({customerDetails?.CUSTOMER_CODE})</Typography>
      <div style={{marginTop:30}}>
        {isEditingCustomer ? 
        <EditInfo customerDetails={customerDetails} onSubmit={handleCustomerSaveClick} />
        :
        <DisplayInfo customerDetails={customerDetails} onEdit={handleCustomerEditClick} />
        }
      </div>
      
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Reg Code" />
        {/* <Tab label="Payment References" /> */}
      </Tabs>
      {selectedTab === 0 && (
        <>
        <Button style={{marginTop:10}} size='small' variant="contained" color="primary" onClick={handleRegCodeCreate}>{loc(l.customerDetailsRegCodeCreate)}</Button>
        <ChillTable header={regCodeHeader} content={codeList} tableTop={280} handleOther={handleDetails} otherButtonText={loc(l.regCodeListDetailsBtn)} excelName={'Reg Code List ('+customerDetails.NAME+')'+moment().format('YYYY-MM-DD')} />
        <RegCodeDialog loading={loading} dialogOpen={dialogOpen} data={dialogData} onClose={handleDialogClose} onSave={handleDialogSave}/>
        </>
      )}
      {/* {selectedTab === 1 && (
        <>
        <ChillRowGrid just={"space-evenly"}>
            <ChillTextField
              label="Payment Reference"
              value={currentRef}
              onChange={(e) => setCurrentRef(e.target.value)}
              required
              style={{paddingRight:8}}
            />
            <ChillTextField
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              style={{paddingRight:8}}
            />
            <Button variant="contained" color="primary" onClick={handleAddUpdateRef}>{(editIndex!==null)?'Edit':'Add'}</Button>
        </ChillRowGrid>
        <ChillTable header={tableHeader} content={paymentRefs} handleEdit={handleEditRef} handleDelete={handleDeleteRef} />
        </>
      )} */}
    </ChillContainer>
  );
}

export default CustomerDetails;
