import { store } from './store'

const BASE_URL = 'https://chillworkhk.com:3001/api/';
const API_KEY = '487487487!@#$';

export const get = async (endpoint) => {
    try {
        const response = await fetch(`${BASE_URL}${endpoint}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
    }
};

export const post = async (endpoint, data) => {
    const state = store.getState();
    const authToken = state.auth.authToken || null;
    try {
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY,
                'Authorization':authToken,
            },
            body: JSON.stringify(data),
        });
        // if (!response.ok) {
        //     throw new Error('Network response was not ok');
        // }
        return await response.json();
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
    }
};
