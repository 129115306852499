import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import InfoIcon from '@mui/icons-material/Info';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState, forwardRef, createContext, useContext, useEffect  } from 'react';
import { l, useLoc } from '../locales/lang';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { clearAuthToken } from '../redux/actions/actions';

const DEV = "DEV"
const PROD = "PROD"
const env = PROD

const WindowHeightContext = createContext();
export const ___DEV___ = (env==="DEV")?true:false;


export const WindowHeightProvider = ({ children }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <WindowHeightContext.Provider value={windowHeight}>
      {children}
    </WindowHeightContext.Provider>
  );
};
export const useWindowHeight = () => {
  const context = useContext(WindowHeightContext);
  if (context === undefined) {
    throw new Error('useWindowHeight must be used within a WindowHeightProvider');
  }
  return context;
};
export function ChillConsole(...args){
  if(___DEV___){
    console.log(...args)
  }
}
export function ChillLoading(props) {
  const loading = props?.loading || false
  return (
    (loading)?
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" style={{position:'absolute',width:'100%',height:'100%',top:0,right:0}}>
      <CircularProgress />
    </Box>
    :<></>
  );
}
export function ChillError(props) {
  const error = props?.error || ""
  return (
    (error)?
    <Typography color="error">{error}</Typography>
    :<></>
  );
}
export const ChillTextField = forwardRef(function ChillTextField(props, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const label = props.label || "";
  const fullWidth = props.fullw || false;
  const type = props.type || 'text';
  const [val, setVal] = useState(props.value);
  const onChange = (e)=>{
    if(props.onChange){
      props.onChange(e)
    }
    setVal(e.target.value);
  } 

  return (
    <TextField
    ref={ref}
    variant="outlined"
    required
    fullWidth={fullWidth}
    label={label}
    value={val}
    type={type}
    style={(fullWidth)?{marginTop:5,marginBottom:5}:{marginTop:5,marginBottom:5}}
    onChange={(e) => onChange(e)}
    />
  );
});
export const ChillSearchTextField = forwardRef(function ChillSearchTextField(props, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const label = props.label || "";
  const fullWidth = props.fullw || false;
  const type = props.type || 'text';
  const [val, setVal] = useState(props.value);
  const onChange = (e)=>{
    if(props.onChange){
      props.onChange(e)
    }
    setVal(e.target.value);
  } 
  const onCancel = (e)=>{
    if(props.onCancel){
      props.onCancel()
    }
    setVal('')
  } 

  return (
    <TextField
    ref={ref}
    variant="outlined"
    required
    fullWidth={fullWidth}
    label={label}
    value={val}
    type={type}
    style={(fullWidth)?{marginTop:5,marginBottom:5}:{marginTop:5,marginBottom:5}}
    onChange={(e) => onChange(e)}
    InputProps={{
    endAdornment: (
        <InputAdornment position="end">
        <IconButton
            onClick={() => onCancel()}
            edge="end"
        >
            <CancelIcon />
        </IconButton>
        </InputAdornment>
    ),
    }}
    />
  );
});
export const ChillSelect = forwardRef(function ChillSelect(props, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const label = props.label || "";
  const fullWidth = props.fullw || false;
  const data = props.data || [];
  const main_key = props.main_key || "";
  const value_key = props.value_key || "";
  const display_key = props.display_key || [];
  const [val, setVal] = useState(props.value || "");
  const onChange = (e)=>{
    if(props.onChange){
      props.onChange(e)
    }
    setVal(e.target.value);
  } 
  const displayText = (item,key_arr)=>{
    const arr = [];
    key_arr.map(m=>{
      arr.push(item[m])
    })
    return arr.join(' - ')
  }

  return(
    <FormControl fullWidth margin="dense">
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        ref={ref}
        labelId="select-label"
        id="select"
        value={val}
        fullWidth={fullWidth}
        onChange={onChange}
      >
        {
        (main_key && value_key && display_key)?
        data.map((item) => (
          <MenuItem key={item[main_key]} value={item[value_key]}>
            {displayText(item,display_key)}
          </MenuItem>
        )):
        data.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))
        }
      </Select>
    </FormControl>
  );
});
export function ChillPasswordField(props){
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const val = props.value;
  const name = props.name || "";
  const [showPassword, setShowPassword] = useState(false);
  const onChange = (e)=>{
    if(props.onChange){
      props.onChange(e)
    }
  } 
  return (
    <TextField
        variant="outlined"
        required
        fullWidth
        label={name}
        value={val}
        type={showPassword ? 'text' : 'password'}
        onChange={(e) => onChange(e)}
        InputProps={{
        endAdornment: (
            <InputAdornment position="end">
            <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            </InputAdornment>
        ),
        }}
    />
  );
}
export function ChillRowGrid(props){
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return(
    <Grid 
    container
    direction={(isMobile)?"column":"row"}
    justifyContent={props.just || ((isMobile)?"left":"center")}
    alignItems={props.align || ((isMobile)?"left":"center")}>
      {props.children}
    </Grid>
  )
}
export function ChillTable(props){
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const windowHeight = useWindowHeight();
  const tableTop = props?.tableTop || 100
  const header = props?.header || []
  const content = props?.content || []
  const haveEdit = (props?.handleEdit)? true : false
  const haveDelete = (props?.handleDelete)? true : false
  const haveOther = (props?.handleOther)? true : false
  const otherButtonText = props?.otherButtonText || ""
  const filter = props?.filter || ''
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const handleSort = (field) => {
    const newField = (sortField===field && sortDirection==='desc')?null:field
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(newField);
    setSortDirection(newDirection);
  };
  const sortedAndFilteredData = content
  .filter((data) => {
    return Object.keys(data).some((key) => 
      String(data[key]).toLowerCase().includes(filter.toLowerCase())
    );
  })
  .sort((a, b) => {
    if (sortField) {
      const isAsc = sortDirection === 'asc' ? 1 : -1;
      const aValue = typeof a[sortField] === 'string' ? a[sortField].toLowerCase() : (a[sortField] === null) ? '' : a[sortField];
      const bValue = typeof b[sortField] === 'string' ? b[sortField].toLowerCase() : (b[sortField] === null) ? '' : b[sortField];
      return aValue < bValue ? -1 * isAsc : aValue > bValue ? 1 * isAsc : 0;
    }
    return 0;
  });
  const handleEdit = (index)=>{
    if(props.handleEdit){
      props.handleEdit(index)
    }
  } 
  const handleDelete = (index)=>{
    if(props.handleDelete){
      props.handleDelete(index)
    }
  } 
  const handleExcel = ()=>{
    const filename = props.excelName || null
    const arr = []
    sortedAndFilteredData.map((row,index)=>{
      const data = {}
      header.map((d)=>{
        data[d.key] = row[d.key]
      })
      arr.push(data)
    })
    exportToExcel(arr,filename)
  } 
  const handleOther = (id)=>{
    if(props.handleOther){
      props.handleOther(id)
    }
  } 

  return (
    <TableContainer component={Paper} elevation={0} style={{marginTop:20, maxHeight: (windowHeight-tableTop)+'px', overflowY: 'auto'}}>
    <Table style={{tableLayout: 'fixed'}}>
      {
        (header.length>0)?
          <TableHead>
            <TableRow>
            { 
            header.map((d,index)=>{
              return(
                <TableCell className='table_ellipsis' key={d.name+index} align={d?.align || "left"}  onClick={() => handleSort(d.key)}>{d.name}
                {sortField === d.key && (sortDirection === 'asc' ? <ArrowUpwardIcon style={{verticalAlign: 'text-bottom',fontSize: 16}}/> : <ArrowDownwardIcon style={{verticalAlign: 'text-bottom',fontSize: 16}}/>)}
                </TableCell>
              )
            })
            }
            {
              (haveEdit || haveDelete || haveOther)?
              <TableCell align="center">
              <IconButton
                  onClick={() => handleExcel()}
                  edge="end"
              >
                  <DownloadIcon />
              </IconButton>
              </TableCell>
              :null
            }
            </TableRow>
          </TableHead>
        :
        <></>
      }
      <TableBody>
        {sortedAndFilteredData.map((row,index) => (
          <TableRow key={index}>
            { 
            header.map((d)=>{
              return(
                <Tooltip key={d.name+d.key+index} title={row[d.key]}>
                  <TableCell className='table_ellipsis' align={d?.align || "left"}>{row[d.key]||'-'}</TableCell>
                </Tooltip>
              )
            })
            }
            {
              (haveEdit || haveDelete || haveOther)?
              <TableCell align="center" style={{padding:0}}>
                {
                  haveEdit?
                  <IconButton aria-label="edit" onClick={() => { handleEdit(index) }}>
                    <EditIcon />
                  </IconButton>
                  :<></>
                }
                {
                  haveDelete?
                  <IconButton aria-label="delete" onClick={() => { handleDelete(index) }}>
                    <DeleteIcon />
                  </IconButton>
                  :<></>
                }
                {
                  haveOther?
                  isMobile?
                  <IconButton aria-label="delete" onClick={() => { handleOther(row.ID) }}>
                    <InfoIcon />
                  </IconButton>
                  :
                  <Button
                      size='small'
                      variant="contained" 
                      color="primary" 
                      onClick={() => handleOther(row.ID)}
                  >
                    {otherButtonText}
                  </Button>
                  :<></>
                }
              </TableCell>
              :null
            }
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
}
export function ChillBox(props){
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const onSubmit = ()=>{
    if(props.onSubmit){
      props.onSubmit()
    }
  } 

  return(
    <Box 
      style={{display:'flex',flexDirection:(isMobile)?'column':'row',width:(isMobile)?'90%':'100%'}} 
      component={"form"} 
      onSubmit={onSubmit}
      justifyContent={props.just || ((isMobile)?"left":"center")}
      alignItems={props.align || ((isMobile)?"left":"center")}>
      {props.children}
    </Box>
  )
}
export function ChillContainer(props){
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const style = {}
  if(isMobile){
    style.padding = 0
    style.width = '95%'
  }
  const handleSubmit = (e)=>{
    e.preventDefault();
    
    if(props.onSubmit){
      props.onSubmit()
    }
  }

  return(
    <Container style={style} component="main" >
      {
        (props.onSubmit)?
        <form onSubmit={handleSubmit}>
        {props.children}
        </form>
        :
        <>
        {props.children}
        </>
      }
    </Container>
  )
}
export function chillApiCheck(response,navigate,dispatch){
 if(response.logout){
  alert('Login session expired')
  dispatch(clearAuthToken(),()=>{
    navigate('/login');
  });
 }else{
  alert(`Error: ${response.message || 'Something went wrong'}`);
 }
}
export function getRefValue(ref){
    const val = (ref)?(ref.current.querySelector('input').value):null

    return val;
}



export function exportToExcel(arr,name){
  if(!Array.isArray(arr)){ return; }
  const filename = name || 'table_data'
  const ws = XLSX.utils.json_to_sheet(arr);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, filename + ".xlsx");
};
export function generateNumberArray(max){
 return Array.from({ length: max }, (_, i) => i + 1);
} 
export function validateEmail(email){
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
}
export function validatePhone(phone){
  const re = /^[0-9]{10}$/;
  return re.test(String(phone));
}
export function checkLength(text, min, max){
  return text.length >= min && text.length <= max;
}
export function validateNumeric(input){
  return !isNaN(input);
}
